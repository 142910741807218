@font-face {
    font-family: 'SourceSansPro-Regular';
    src: url('../fonts/SourceSansPro-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/SourceSansPro-Regular.woff') format('woff'), url('../fonts/SourceSansPro-Regular.ttf') format('truetype'), url('../fonts/SourceSansPro-Regular.svg#SourceSansPro-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('../fonts/HelveticaNeue.eot?#iefix') format('embedded-opentype'), url('../fonts/HelveticaNeue.woff') format('woff'), url('../fonts/HelveticaNeue.ttf') format('truetype'), url('../fonts/HelveticaNeue.svg#HelveticaNeue') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue-Bold';
    src: url('../fonts/HelveticaNeue-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/HelveticaNeue-Bold.woff') format('woff'), url('../fonts/HelveticaNeue-Bold.ttf') format('truetype'), url('../fonts/HelveticaNeue-Bold.svg#HelveticaNeue-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

body {
    font-family: 'HelveticaNeue';
    font-size: 14px;
    color: #000;
    letter-spacing: 1px;
}

h1 {
    font-size: 24px;
    color: rgb(0, 0, 0);
    text-align: center;
    margin: 0px auto 50px;
    position: relative;
    padding: 0;
    white-space: nowrap;
    display: inline-block;
}

h1:after {
    content: "";
    width: 42%;
    height: 5px;
    position: absolute;
    border-top: 2px solid #000;
    border-bottom: 2px solid #000;
    bottom: -15px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
}

h2 {
    font-size: 18px;
    color: rgb(0, 0, 0);
    margin-top: 0;
    white-space: nowrap;
    display: inline-block;
    position: relative;
    font-family: 'HelveticaNeue-Bold';
    text-transform: uppercase;
}

h2:after {
    content: "";
    width: 40px;
    height: 1px;
    position: absolute;
    border-bottom: 2px solid #000;
    bottom: -10px;
    left: 0;
}

h3 {
    font-size: 16px;
    color: rgb(0, 0, 0);
    margin-top: 0;
    white-space: normal;
    display: inline-block;
    position: relative;
}

h3:after {
    content: "";
    width: 30px;
    height: 1px;
    position: absolute;
    border-bottom: 2px solid #000;
    bottom: -10px;
    left: 0;
}

a,
button {
    transition: 0.5s all;
    -webkit-transition: 0.5s all;
    -moz-transition: 0.5s all;
    -ms-transition: 0.5s all;
    -o-transition: 0.5s all;
}

.navbar-default .navbar-nav>li>a:focus,
.navbar-default .navbar-nav>li>a:hover {
    color: white;
    background-color: transparent;
}

a {
    text-decoration: none;
}

a:focus,
a:hover,
a:active {
    outline: 0;
    box-shadow: 0;
}

strong,
.h-bold {
    font-family: 'HelveticaNeue-Bold';
}

s-reg {
    font-family: 'SourceSansPro-Regular';
}


/*=== Custom Styles ===*/

header {
    background-color: #3025AA;
    color: #fff;
}

.header-1 strong {
    font-size: 24px;
    color: rgb(255, 255, 255);
    line-height: 1;
    letter-spacing: 1px;
}

.header-1 a {
    font-size: 14px;
    color: rgb(255, 255, 255);
}

.header-1 {
    padding-top: 30px;
    padding-bottom: 20px;
}

.navbar-default {
    background-color: transparent;
    border: none;
    margin-bottom: 0;
    min-height: inherit;
}

.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
    border: none;
    padding: 0px;
}

.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:focus,
.navbar-default .navbar-nav>.active>a:hover,
.navbar-default .navbar-nav>li>a:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.3);
}

.navbar-default .navbar-nav>li>a {
    color: #fff;
    font-size: 14px;
}

.divider {
    border-bottom: 1px solid #858AA5;
}


/*.navbar-default .navbar-nav>.active>a:after {
    content: "";
    height: 1px;
    width: 27%;
    background-color: #fff;
    position: absolute;
    left: 15px;
    bottom: 15px;
}*/

.navbar-default .navbar-nav>li>a.active {
    font-weight: bold;
    font-family: 'HelveticaNeue-Bold';
}

.navbar-default .navbar-nav.navbar-right>li>a:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0);
}

section.hero img {
    width: 100%;
}

.main-h {
    font-size: 24px;
    color: rgb(0, 0, 0);
    text-align: center;
    margin: 0px auto 50px;
    position: relative;
    padding: 0;
    white-space: nowrap;
    display: inline-block;
}

.abt-sec {
    padding-top: 40px;
    padding-bottom: 40px;
}

.abt-sec p {
    font-size: 16px;
    letter-spacing: 0.5px;
}

.main-h:after {
    content: "";
    width: 42%;
    height: 5px;
    position: absolute;
    border-top: 2px solid #000;
    border-bottom: 2px solid #000;
    bottom: -15px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
}

.sc-list {
    padding-left: 0;
    margin-bottom: 0;
}

.sc-list li a {
    color: #1275b1;
    font-size: 34px;
}

footer {
    background-color: #f0f0f2;
    padding: 13px 0 11px;
    margin-top: 30px;
}

.f-col-1,
.f-col-3 {
    padding-top: 16px;
}

.f-col-3 a {
    color: rgb(48, 44, 167);
}

.navbar-default .navbar-toggle {
    border-color: #fff;
    border-radius: 0px;
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: rgba(255, 255, 255, 0.72);
}

.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:hover {
    background-color: rgb(110, 102, 196);
}

.navbar-default .navbar-nav.navbar-right>li>a:after {
    content: "|";
    position: absolute;
    right: 0;
}

.navbar-default .navbar-nav.navbar-right>li:last-child>a:after {
    content: "";
}

.img-holder img.img-responsive {
    margin: 0px auto 20px;
}

ul.cat-ul {
    list-style: none;
    padding-left: 0;
}

a.btn.btn-default.cat-btn {
    border: none;
    background-color: rgb(240, 240, 242);
    width: 262px;
    border-radius: 0;
    color: rgb(0, 0, 0);
    font-size: 14px;
    padding: 14px;
    text-transform: uppercase;
    white-space: normal;
}

a.btn.btn-default.cat-btn:hover {
    border: none;
    background-color: rgb(188, 188, 188);
}

ul.cat-ul li {
    margin-bottom: 10px;
}

.row.inner-row {
    margin-top: 20px;
    margin-bottom: 70px;
}

.main-h2 {
    font-size: 18px;
    color: rgb(0, 0, 0);
    margin-top: 0;
    white-space: nowrap;
    display: inline-block;
    position: relative;
}

.main-h2:after {
    content: "";
    width: 42%;
    height: 1px;
    position: absolute;
    border-bottom: 2px solid #000;
    bottom: -10px;
    left: 0;
}

.points li {
    font-size: 16px;
    color: rgb(0, 0, 0);
}

ul.points {
    padding-left: 0;
    list-style: none;
}

ul.points li:before {
    content: "-";
    position: absolute;
    left: 0;
    width: 20px;
    font-weight: bold;
    font-family: 'HelveticaNeue-Bold';
}

ul.points li {
    margin-top: 30px;
    position: relative;
    padding-left: 15px;
}

.col-lt {
    margin-bottom: 30px;
}

ol.numbers li {
    margin-top: 30px;
    position: relative;
    padding-left: 15px;
}

.numbers li {
    font-size: 16px;
    color: rgb(0, 0, 0);
}

ol.numbers {
    padding-left: 20px;
}

.tab-row h3 {
    font-size: 16px;
    margin-top: 0;
}

.row.tab-row {
    margin-top: 25px;
}

.tab-rt {
    padding-top: 15px;
}

.main-h2.inner-h2:after {
    max-width: 36px;
}

.main-h2.inner-h2 {
    margin-top: 30px;
    margin-bottom: 20px;
}

.inner-ul {
    margin-top: -5px;
}

.tab-lt img {
    margin: auto;
}

section.hero.res-hero {
    background-color: #F0F0F2;
    padding-top: 70px;
    padding-bottom: 70px;
}

.res-sec ul.points li {
    margin-top: 5px;
}

.res-sec .main-h2 {
    margin-bottom: 30px;
    margin-top: 25px;
}

.res-sec .main-h2:first-child {
    margin-top: 0px;
}

.webclix-logo {
    position: relative;
    bottom: 6px;
    right: 35px;
    width: 33px;
    display: block;
}

.navbar-fixed-bottom {
    z-index: 0;
}

.navbar-fixed-bottom,
.navbar-fixed-top {
    position: relative;
}

.content-margin {
    margin-left: 80px;
}


/* footer */

footer {
    width: 100%;
    min-height: 100px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.item1 {
    width: 25%;
    float: left;
    text-align: center;
}

.item2 {
    width: 25%;
    float: left;
    text-align: right;
}

.item3 {
    width: 25%;
    float: left;
    text-align: right;
}

.item3 img {
    width: 30px;
    padding-left: 15px;
}

.item4 {
    width: 25%;
    float: left;
}

@media screen and (max-width: 780px) {
    .item1 {
        width: 50%;
    }
    .item2 {
        width: 50%;
        text-align: center;
        flex-order: 3;
    }
    .item3 {
        width: 50%;
        text-align: center;
    }
    .item4 {
        width: 50%;
        text-align: center;
    }
}
