        @media (min-width: 1200px) {
        .webclix-logo {
            margin-right: 0px!important;
        }
    }
    
    @media (max-width: 1199px) {
        .img-holder img.img-responsive {
            width: 262px!important;
        }
        .img-holder img.img-responsive {
            margin: 0px 0px 20px!important;
        }
        .f-col-3 a {
            margin-right: 0px!important;
        }
        @media (max-width: 991px) {
            .navbar-default .navbar-nav>li>a {
                padding: 10px;
            }
            .navbar-default .navbar-nav>.active>a:after {
                left: 10px;
                bottom: 10px;
            }
        }
        @media screen and (max-width: 1199px) and (min-width: 767px) {
            .f-col-3 a {
                margin-right: -16px!important;
            }
        }
        @media (max-width: 767px) {
            .navbar-default .navbar-collapse,
            .navbar-default .navbar-form {
                padding: 0px 15px;
            }
            .navbar-default .navbar-nav>.active>a:after {
                width: 18px;
            }
            .sc-list {
                display: inline-block !important;
                vertical-align: middle;
                margin-right: 84%;
            }
            footer {
                text-align: center;
            }
            .f-col-1,
            .f-col-3 {
                text-align: center !important;
            }
            .navbar-default .navbar-nav.navbar-right>li>a:after {
                content: "";
            }
            a.btn.btn-default.cat-btn {
                width: 100%!important;
            }
            .tab-content {
                margin-left: 0px!important;
            }
            .img-holder img.img-responsive {
                width: 100%!important;
            }
            .img-holder img.img-responsive {
                margin: 0px auto 20px!important;
            }
            .f-col-3 a {
                margin-right: 13px!important;
                text-align: center;
            }
        }
        @media (max-width: 535px) {
            .row.header-1 .col-xs-6 {
                width: 100%;
                text-align: center;
            }
            .header-1 {
                padding-top: 15px;
                padding-bottom: 15px;
            }
            section.hero.res-hero {
                padding-top: 30px;
                padding-bottom: 30px;
            }
        }
        @media (max-width: 398px) {}
        @media screen and (max-width: 398px) and (min-width: 767px) {
            .col-sm-3.col-xs-6.f-col-1 span {
                margin-right: 60%
            }
            .f-col-3 a {
                margin-left: 20%;
            }
        }
